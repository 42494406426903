<template>
  <div id="exec-bios" :class="$route.name">
    <Navbar class="white-text white-logo light-menu-icon" />

    <div class="background">
      <img src="@/assets/wave-footer.png" alt="background image" />
    </div>

    <router-view></router-view>

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "ExecBios",
  components: {
    Navbar,
    Footer
  }
};
</script>

<style lang="scss">
#exec-bios {
  position: relative;

  #footer {
    margin-top: 20vh;
  }
  > .background {
    position: absolute;
    top: -8px;
    left: -50px;
    width: calc(100vw + 50px);
    z-index: -1;

    img {
      width: 100vw;
      transform: scale(1, -1);
    }

    @media (min-width: $desktop) {
      top: 0;
    }
  }

  &.exec-bios-index,
  &.exec-bios-view {
    > .background {
      background-color: $blue;
      padding-top: 7vh;

      img {
        transform: scale(1, -1) translateY(-30px);
      }

      @media (min-width: $desktop) {
        img {
          transform: scale(1, -1) translateY(-110px);
        }
      }
    }
  }
  &.exec-bios-view {
    > .background {
      img {
        transform: scale(1, -1) translate(40px, -50px);
      }

      @media (min-width: $desktop) {
        transform: translateY(-80%);
        background-color: transparent;

        img {
          transform: scale(1, -1) translateY(-140px);

          @media (min-width: $xl-desktop) {
            transform: scale(1.5, -1) translateY(-110px);
          }
        }
      }
    }
  }
}
</style>
